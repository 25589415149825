/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {login, socialLogin} from '../redux/AuthCRUD'
import {GoogleLogin} from 'react-google-login'
import FacebookLogin from 'react-facebook-login'
import GoogleButton from 'react-google-button'
import axios from 'axios'
import './style.css'
import { DesginTool } from '../../../pages/tool/DesginTool'

declare var process: {
  env: {
    REACT_APP_GOOGLE_ID: string
    REACT_APP_FACEBOOK_ID: string
    REACT_APP_API_URL: string
  }
}
const scrollToRef = (ref:any) => window.scrollTo(100, ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' }))  
const clientID = process.env.REACT_APP_GOOGLE_ID
const appId = process.env.REACT_APP_FACEBOOK_ID
const API_URL = process.env.REACT_APP_API_URL
const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const myRef = useRef(null);
  const [loading, setLoading] = useState(false)
  const [loadingSocial, setLoadingSocial] = useState(false)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        login(values.email, values.password)
          .then(
            ({
              data: {
                data: {
                  access_token,
                  user: {email_verified_at},
                },
              },
            }) => {
              // console.log(email_verified_at)
              if (email_verified_at === null) {
                const resend = () => {
                  axios
                    .post(`${API_URL}/api/auth/email/verify/resend`, {email: formik.values.email})
                    .then(() => {
                      setStatus(<p>Please Check Your Email</p>)
                    })
                }
                setLoading(false)
                setSubmitting(false)

                setStatus(
                  <p>
                    You should verify your email So if You don't receive email{' '}
                    <a href='#' onClick={resend}>
                      Press to resend
                    </a>
                  </p>
                )
              } else {
                setLoading(false)
                dispatch(auth.actions.login(access_token))
              }
            }
          )
          .catch(() => {
            setLoading(false)
            setSubmitting(false)
            setStatus(`The login detail is incorrect`)
            scrollToRef(myRef)
          })
      }, 1000)
    },
  })

  const googleLogin = (res: any) => {
    setTimeout(() => {
      socialLogin(res.accessToken, 'google')
        .then(({data: {access_token}}) => {
          dispatch(auth.actions.login(access_token))
        })
        .catch((err) => {
          formik.setStatus(`'The login detail is incorrect '${err}`)
        })
    }, 1000)
  }

  const googleOnFailure = (res: any) => {
    formik.setStatus(`'The login detail is incorrect '${res}`)
  }

  const responseFacebook = (res: any) => {
    console.log('facebook login response ', res)
    localStorage.setItem('fbToken',res.accessToken)
    localStorage.setItem('userFbId',res.id)
    setTimeout(() => {
      setLoadingSocial(true)
      socialLogin(res.accessToken, 'facebook')
        .then(({data: {access_token}}) => {
          dispatch(auth.actions.login(access_token))
          setLoadingSocial(false)
        })
        .catch((err) => {
          formik.setStatus(`'The login detail is incorrect '${err.message}`)
        })
    }, 1000)
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In to I3LANY</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link>
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger' ref={myRef}>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{marginLeft: '5px'}}
            >
              Forgot Password ?
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        {/* begin::Separator */}

        <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div>
        {/* end::Separator */}

        {/* begin::Google link */}

        <div className='googleButton'>
          <GoogleLogin
            clientId={clientID}
            onSuccess={googleLogin}
            onFailure={googleOnFailure}
            render={(renderProps) => (
              <GoogleButton
                className='googleButton'
                type='dark'
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                Sign in with Google
              </GoogleButton>
            )}
            cookiePolicy={'single_host_origin'}
          />
        </div>

        {/* end::Google link */}
        <div className='googleButton'>
          {!loadingSocial && (
            <FacebookLogin
              appId={appId} // appId
              fields='name,email,picture'
              scope='public_profile,email'
              callback={responseFacebook}
              icon='fa-facebook'
            />
          )}
          {loadingSocial && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </div>
      </div>
      {/* end::Action */}
    </form>
  )
}
