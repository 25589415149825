import React ,{ useState } from "react";
import { observer } from "mobx-react-lite";
import {Button,Navbar,Alignment,Divider,Dialog,Classes,} from "@blueprintjs/core";
import DownloadButton from "polotno/toolbar/download-button";
import styled from "polotno/utils/styled";
import { Link } from "react-router-dom";
import * as auth from '../../modules/auth/redux/AuthRedux';
import { useDispatch } from 'react-redux';
// dispatch(auth.actions.picture(item.preview));
const data:any = localStorage.getItem('persist:v100-demo2-auth');
// console.log(data);
// const dispatch = useDispatch();
const token =data?JSON.parse(data).accessToken:"";
const API_URL = process.env.REACT_APP_API_URL;

const NavbarContainer = styled("div")`
  @media screen and (max-width: 500px) {overflow-x: auto;overflow-y: hidden;max-width: 100vw;}`;
  const axios = require('axios');
const NavInner = styled("div")`
  @media screen and (max-width: 500px) {
    display: flex;
  }
`;

export const TopBar = observer(({ store }:any) => {
  const dispatch = useDispatch();
  const inputRef:any = React.useRef();
  const [loading, setLoading] = useState(false);
  const [faqOpened, toggleFaq] = React.useState(false);
  let blobFile = async(url:any)=>{
    const response = await axios({
       url: url,
       method: 'GET',
       responseType: 'blob', // Important
     })
     return response.data;
     }
    const save = async() => {
    setLoading(true)
    const json =await store.toJSON();
    const image =await store.toDataURL();
    const url = 'data:text/json;base64,' +
    window.btoa(unescape(encodeURIComponent(JSON.stringify(json))));
    const JsonFileBlob = await blobFile(url);
    const previewBlob = await blobFile(image);
    const jsonFile = new File([JsonFileBlob],"template.json");
    const preview = new File([previewBlob],"template.png");
    const formData = new FormData();
    formData.append("json_file", jsonFile);
    formData.append("preview", preview);
    formData.append("post-image", preview);
     axios({
      method  : 'post',
      url : `${API_URL}/api/store-my-template`,
      data : formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${token} `
      },

    }).then((res:any)=>{
      setLoading(false)
      alert("You are Saving Template Successfully");
      window.location.reload();
    }).catch((err:any) => {throw err});
  }
  const saveBeforePostOnFacebook = async() => {
    setLoading(true)
    const json =await store.toJSON();
    const image =await store.toDataURL();
    const url = 'data:text/json;base64,' +
    window.btoa(unescape(encodeURIComponent(JSON.stringify(json))));
    const JsonFileBlob = await blobFile(url);
    const previewBlob = await blobFile(image);
    const jsonFile = new File([JsonFileBlob],"template.json");
    const preview = new File([previewBlob],"template.png");
    const formData = new FormData();
    formData.append("json_file", jsonFile);
    formData.append("preview", preview);
    formData.append("post-image", preview);
     axios({
      method  : 'post',
      url : `${API_URL}/api/store-my-template`,
      data : formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization':`Bearer ${token} `
      },

    }).then((res:any)=>{
      setLoading(false)
     // console.log(res.data.data.preview)
      dispatch(auth.actions.picture(res.data.data.preview));

    }).catch((err:any) => {
      throw err


    });
  }
  return (
    <NavbarContainer className="bp3-navbar">
      <NavInner>
        <Navbar.Group align={Alignment.LEFT}>
          <Button
            icon="new-object"
            minimal
            onClick={() => {
              const ids = store.pages
                .map((page:any) => page.children.map((child:any) => child.id))
                .flat();
              const hasObjects = ids?.length;
              if (hasObjects) {
                if (!window.confirm("Remove all content for a new design?")) {
                  return;
                }
              }
              const pagesIds = store.pages.map((p:any) => p.id);
              store.deletePages(pagesIds);
              store.addPage();
            }}
          >
            New
          </Button>
          <label htmlFor="load-project">
            <Button
              icon="folder-open"
              minimal
              onClick={() => {
                const doc:any = document.querySelector("#load-project");
                doc.click();
              }}
            >
              Open
            </Button>
            <input
              type="file"
              id="load-project"
              accept=".json,.polotno"
              ref={inputRef}
              style={{ width: "180px", display: "none" }}
              onChange={(e:any) => {
                var input = e.target;

                if (!input.files.length) {
                  return;
                }

                var reader = new FileReader();
                reader.onloadend = function () {
                  var text:any = reader.result;
                  let json;
                  try {
                    json = JSON.parse(text);
                  } catch (e) {
                    alert("Can not load the project.");
                  }

                  if (json) {
                    store.loadJSON(json);
                  }
                };
                reader.onerror = function () {
                  alert("Can not load the project.");
                };
                reader.readAsText(input.files[0]);
              }}
            />
          </label>
          <Button
            icon="floppy-disk"
            minimal
            onClick={save} >
                  {!loading && <span className='indicator-label'>Save</span>}
               {loading && (
                 <span className='indicator-progress' style={{display: 'block'}}>
                   Please wait...
                   <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                 </span>
               )}

          </Button>
          <button type="button"
className="btn btn-primary"
data-bs-toggle="modal"
data-bs-target="#kt_modal_1"
onClick={saveBeforePostOnFacebook}
>


Post on Facebook

</button>
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          {/* <a
          href="https://www.producthunt.com/posts/polotno-studio?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-polotno-studio"
          target="_blank"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=281373&theme=dark"
            alt="Polotno Studio - Canva-like design editor, without signups or ads. | Product Hunt"
            style={{ height: '30px', marginBottom: '-4px' }}
          />
        </a> */}

          <Divider />
          <DownloadButton store={store} />
          <Link className="dashboard-btn" to="/">Dashboard</Link>
          {/* <NavbarHeading>Polotno Studio</NavbarHeading> */}
        </Navbar.Group>
        <Dialog
          icon="info-sign"
          onClose={() => toggleFaq(false)}
          title="About Polotno Studio"
          isOpen={faqOpened}
          style={{
            width: "80%",
            maxWidth: "700px",
          }}
        >
          <div className={Classes.DIALOG_BODY}>
            <h2>What is Polotno Studio?</h2>
            <p>
              <strong>Polotno Studio</strong> - is a web application to create
              graphical designs. You can mix image, text and illustrations to
              make social media posts, youtube previews, podcast covers,
              business cards and presentations.
            </p>
            <h2>Is it Open Source?</h2>
            <p>
              Partially. The source code is available in{" "}
              <a
                href="https://github.com/lavrton/polotno-studio"
                target="_blank" rel="noreferrer"
              >
                GitHub repository
              </a>
              . The repository doesn't have full source.{" "}
              <strong>Polotno Studio</strong> is powered by{" "}
              <a href="https://polotno.dev/" target="_blank" rel="noreferrer">
                Polonto SDK project
              </a>
              . All core "canvas editor" functionality are implemented by{" "}
              <strong>polotno</strong> npm package (which is not open source at
              the time of writing this text).
            </p>
            <p>
              Polotno Studio is build on top of Polotno SDK to provide a
              desktop-app-like experience.
            </p>
            <h2>Who is making Polotno Studio?</h2>
            <p>
              My name is Anton Lavrenov{" "}
              <a href="https://twitter.com/lavrton" target="_blank" rel="noreferrer">
                @lavrton
              </a>
              . I am founder of Polotno project. As the maintainer of{" "}
              <a href="https://konvajs.org/" target="_blank" rel="noreferrer">
                Konva 2d canvas framework
              </a>
              , I created several similar apps for different companies around
              the world. So I decided to compile all my knowledge and experience
              into reusable Polotno project.
            </p>
            <h2>
              Why Polotno Studio has no signups and no ads? How are you going to
              support the project financially?
            </h2>
            <p>
              Instead of monetizing the end-user application{" "}
              <strong>Polotno Studio</strong> I decided to make money around
              developers tools with{" "}
              <a href="https://polotno.dev/" target="_blank" rel="noreferrer">
                Polonto SDK
              </a>
              .
            </p>
            <p>
              <strong>Polotno Studio</strong> is a sandbox application and
              polished demonstration of{" "}
              <a href="https://polotno.dev/" target="_blank" rel="noreferrer">
                Polonto SDK
              </a>{" "}
              usage.
            </p>
            <p>
              With{" "}
              <a href="https://polotno.dev/" target="_blank" rel="noreferrer">
                Polonto SDK
              </a>{" "}
              you can build very different application with very different UI.
            </p>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={() => toggleFaq(false)}>Close</Button>
            </div>
          </div>
        </Dialog>
      </NavInner>
    </NavbarContainer>
  );
});
