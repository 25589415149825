import { observer } from "mobx-react-lite";
import { InputGroup } from "@blueprintjs/core";
import { useInfiniteAPI } from "polotno/utils/use-api";
import { SectionTab } from "polotno/side-panel";
import { ImagesGrid } from "polotno/side-panel/images-grid";
import * as auth from '../../modules/auth/redux/AuthRedux'
import { useDispatch } from "react-redux";
const API_URL = process.env.REACT_APP_API_URL;
export const CustomComponent = observer(({ store }: any) => {
  // load data
  const dispatch = useDispatch();
  const { data, isLoading, loadMore, setQuery } = useInfiniteAPI({
    getAPI: ({ page, query }) =>
      `${API_URL}/api/design-templates?page=${page}`,
    getSize: (res) => Math.floor(res.count / res.limit),
  }); 
// const firstSelected = store.selectPage(store.pages);
//   console.log(firstSelected);
  // console.log( store.selectPage(store.pages[0].id));
  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <InputGroup
        leftIcon="search"
        placeholder="Search..."
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        style={{
          marginBottom: "20px",
        }}
      />
      <ImagesGrid
        shadowEnabled={false}
        images={data?.map((data) => data.data).flat()!}
        getPreview={(item) => item.preview}
        isLoading={isLoading}
        onSelect={async (item, pos, element) => {
          // download selected json
          const req = await fetch(item.json_file);
          console.log(item);
          dispatch(auth.actions.picture(item.preview));
          const json = await req.json();
          // just inject it into store
          store.loadJSON(json);
        }}
        rowsNumber={2}
        loadMore={loadMore}
      />
    </div>
  );
});

// define the new custom section
export const CustomTemplateSection = {
  name: "custom-template-apis",
  Tab: (props:any) => (
    <SectionTab name="Templates" {...props} >

<i className="blk fas fa-camera-retro"></i>

    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: CustomComponent,
};
