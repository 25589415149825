import { useInitFbSDK } from "./facebookPost";
import { useSelector } from "react-redux";
import './FacebookComp.css';
import React, { useEffect, useState } from 'react';
import { KTSVG } from "../../../_metronic/helpers";
import axios from "axios";

declare const window: Window &
   typeof globalThis & {
     FB: any;
     fbAsyncInit:any;
   }
   let PAGE_ID: string;

   // const axios = require('axios');
export const FacebookComponents = () => {
    const isFbSDKInitialized = useInitFbSDK();

const [fbUserAccessToken, setFbUserAccessToken] = React.useState();
const [fbPageAccessToken, setFbPageAccessToken] = React.useState();
const [postText, setPostText] = React.useState<string>();
// const [postImage, setPostImage] = React.useState<string>();
const [isPublishing, setIsPublishing] = React.useState(false);

// user fb id
const [fbUserId,setFbUserId] = useState(null)
let [pages,setPages] = useState([])

function getUserFbPages(){

  let subscribeBody = {
    user_id: localStorage.getItem('userFbId'),
    access_token: localStorage.getItem('fbToken')
  }
  let pagesBody = {
    user_id: localStorage.getItem('userFbId'),
  }
  let reqHeaders = {
    headers: {
      "Content-Type": "application/json"
    }
  }
  axios.post('https://fbwh.epsilonfinder.com/fbservice/subscribeuser',subscribeBody,reqHeaders).then((response: any) => {
    console.log('subscribe response ', response)
    if( response.data.success ){
      axios.post('https://fbwh.epsilonfinder.com/fbservice/userpages',pagesBody,reqHeaders).then((response: any) => {
        setPages(response.data.data)
        console.log('pages response',response)
      })
    }
    
  })

}

const logInToFB = React.useCallback(() => {
  window.FB.login((response:any) => {
    localStorage.setItem('fbToken',response.authResponse.accessToken)
    localStorage.setItem('userFbId',response.authResponse.userID)
    console.log('create post facebook login response ',response)
    setFbUserId(response.authResponse.userID)
    setFbUserAccessToken(response.authResponse.accessToken);
    getUserFbPages();
  },{scope: 'public_profile,email,pages_show_list,pages_manage_metadata,pages_manage_engagement,pages_manage_posts'});
}, []);

useEffect(() => {

  if( localStorage.getItem('userFbId') ){
    getUserFbPages();
  }

},[])

// Logs out the current Facebook user
const logOutOfFB = React.useCallback(() => {
  window.FB.logout(() => {
    setFbUserAccessToken(null!);
    setFbPageAccessToken(null!);
  });
}, []);

// Checks if the user is logged in to Facebook
React.useEffect(() => {
  if (isFbSDKInitialized) {
    window.FB.getLoginStatus((response:any) => {
      setFbUserAccessToken(response.authResponse?.accessToken);
    });
  }
}, [isFbSDKInitialized]);

// Fetches an access token for the page
React.useEffect(() => {
  if (fbUserAccessToken) {
    window.FB.api(
      `/${PAGE_ID}?fields=access_token&access_token=${fbUserAccessToken}`,
      ({ access_token }:any) => setFbPageAccessToken(access_token)
    );
  }
}, [fbUserAccessToken]);

// Publishes a post on the Facebook page
// console.log(postImage);
const [imageUrlFacebook,setimageUrlFacebook]=useState('');
const sendPostToPage = React.useCallback(() => {
  setIsPublishing(true);

  window.FB.api(
    `/${PAGE_ID}/photos`,
    "POST",
    {
     url:imageUrlFacebook,
     caption: postText,
    access_token: fbPageAccessToken,
    },
    () => {
      setPostText('');
      // setPostImage('');
      setIsPublishing(false);
    }
  );
}, [fbPageAccessToken, imageUrlFacebook, postText]);
// // To prepare image
// const image = store.toDataURL();
// console.log(image.preview);
//const previewBlob =  blobFile(image);

// const [imageUrlFacebook,setimageUrlFacebook]=useState('');
// const getPages:any = React.useCallback<(
//   () =>{
//     return window.FB.api( "/10159694960568774/accounts","GET")

// });
// console.log(getPages);
const url:any = useSelector((state:any)=>state.auth.picture);
useEffect(()=>{

  setimageUrlFacebook(url);
},[url])

function myFunction() {
  var input, filter, ul, li, a, i, txtValue;
  input = document.getElementById("myInput") as HTMLInputElement;
  if( input ){
    filter = input.value.toUpperCase();
    ul = document.getElementById("myUL");
    if( ul ){
      li = ul.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
          a = li[i].getElementsByTagName("a")[0];
          txtValue = a.textContent || a.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
              li[i].style.display = "";
          } else {
              li[i].style.display = "none";
          }
      }
    }
  }
}

let [choosedPage,setChoosedPage] = useState(false)

function HandleChoosePage(id: number){
  PAGE_ID = id.toString();
  console.log('page ',PAGE_ID)
  var pages = document.getElementsByClassName('userPage')
  for( var i = 0; i < pages.length ; i++ ){
    if( pages[i].getAttribute('id') === 'userPage'+id ){
      pages[i].classList.add('activePage')
    }else{
      pages[i].classList.remove('activePage')
    }
  }
  setChoosedPage(true)
}

return <>

<div className="modal fade" tabIndex={-1} id="kt_modal_1">
<div className="modal-dialog">
  <div className="modal-content">

    <div className="modal-header">

      <h5 className="modal-title">Post Facebook</h5>
      <div
        className="btn btn-icon btn-sm btn-active-light-primary ms-2"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        <KTSVG
          path="/media/icons/duotune/arrows/arr061.svg"
          className="svg-icon svg-icon-2x"
        />
      </div>
    </div>

    <div className="modal-body">
      
      {

        localStorage.getItem('userFbId') &&

        <React.Fragment>

          <input type="text" id="myInput" onKeyUp={myFunction} placeholder="Search..." />

          <ul id="myUL">

          {

            pages.map((page: any) => {
              return <li className="userPage" id={"userPage"+page.fbid.toString()} onClick={() => { HandleChoosePage(page.fbid) }}>
                <img alt=""/>
                <div>
                  <a>{page.name}</a>
                </div>
              </li>
            })

          }

          </ul>

        </React.Fragment>

      }

    <main id="app-main" className="myBody">
      {fbPageAccessToken ? (
        <section className="app-section">
          <img src={imageUrlFacebook} alt="Faceurl"/>
          <textarea
            value={postText}
            onChange={(e:any) => setPostText(e.target.value)}
            placeholder="Message..."
            rows={8}
            disabled={isPublishing}
          />

        </section>
      ) : (
        !localStorage.getItem('userFbId') &&
        <button onClick={logInToFB} className="btn btn-primary confirm-btn">
          Login with Facebook
        </button>
      )}
    </main>
    </div>
    {
      localStorage.getItem('userFbId') &&
      <div className="modal-footer">
      <button
    //  onClick={sendPostToPage}

    //  disabled={!postText || isPublishing}
        type="button"
        className="btn btn-light confirm-btn"
        data-bs-dismiss="modal"
      >
        Close
      </button>
      <button
       onClick={sendPostToPage}

       disabled={!choosedPage}

         className="btn btn-primary confirm-btn"
         data-bs-dismiss="modal"

      type="button" >
        Send Post
      </button>
      {/* <button onClick={getPages} className="btn btn-primary">Pages</button> */}
    </div>
    }
     
  </div>
</div>
</div>
</>

}
