import {FC, useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {useDispatch} from 'react-redux'
import axios from 'axios'


const API_URL = process.env.REACT_APP_API_URL
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/get-user`

const HeaderUserMenu: FC = () => {
  const [dataFromBrowser, setDataFromBrowser] = useState({name: '', email: ''})
  useEffect(() => {
    const data: any = async () => {
      const dataLocalStorage: any = await localStorage.getItem('persist:v100-demo2-auth')

      const token: any = await JSON.parse(dataLocalStorage).accessToken

      const userData = await axios.get(GET_USER_BY_ACCESSTOKEN_URL, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })

      setDataFromBrowser(userData.data.data)
    }
    data()
  }, [])
 
  // const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const dispatch = useDispatch()
  const logout = () => {
    dispatch(auth.actions.logout())
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src='/media/avatars/blank.png' />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>{dataFromBrowser.name}</div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {dataFromBrowser.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
