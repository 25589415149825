// import React from 'react';
// import ReactDOM from 'react-dom';
import React from "react";
import "./style.css";
// import { useInitFbSDK } from "./facebookPost";
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css'
import { CustomTemplateSection } from "./CustomComponent";
import { CustomUserTemplate }    from "./UserTemplate"
import { FacebookComponents } from "./FacebookComponent";
// import { loadFile } from "./file";
//
import './style.css'
import { TopBar } from './topbar';
// import { KTSVG } from "../../../_metronic/helpers";
// declare const window: Window &
//    typeof globalThis & {
//      FB: any;
//      fbAsyncInit:any;
//    }
//    const PAGE_ID = "109697068135492";
// const { unstable_registerShapeModel } = require('polotno/config');
const {PolotnoContainer, SidePanelWrap, WorkspaceWrap} = require('polotno')
const {Workspace} = require('polotno/canvas/workspace')
const {SidePanel} = require('polotno/side-panel')
const {Toolbar} = require('polotno/toolbar/toolbar')
const {ZoomButtons} = require('polotno/toolbar/zoom-buttons')
const {createStore} = require('polotno/model/store')

const {

  TextSection,
  PhotosSection,
  ElementsSection,
  UploadSection,
  BackgroundSection,
  SizeSection,
} = require('polotno/side-panel')

const store = createStore({
  key: 'CCn3n_h5Vt73aHeyxwwn',
  showCredit: true,
})
 store.addPage()
 store.openSidePanel('custom-template-apis');
// unstable_registerShapeModel({
//   type: 'star',
//   radius: 100,
//   fill: 'black',
//   numPoints: 6,
// });

//  let blobFile = async(url:any)=>{
//     const response = await axios({
//        url: url,
//        method: 'GET',
//        responseType: 'blob', // Important
//      })
// // define the new custom section
// const CustomSection = {
//   name: 'Your Template',
//   Tab: (props:any) => (
//     <SectionTab name="Your Template" {...props}>

//     </SectionTab>
//   ),
// //   // we need observer to update component automatically on any store changes
//   Panel: observer(() => {
//     return (
//       <div>
//         <p>Here we will define our own custom tab.</p>
//         <p>Elements on the current page: {store.activePage?.children.length}</p>
//       </div>
//     );
//   }),
// };

// // const useHeight = () => {
// //   const [height, setHeight] = React.useState(window.innerHeight);
// //   React.useEffect(() => {
// //     window.addEventListener("resize", () => {
// //       setHeight(window.innerHeight);
// //     });
// //   }, []);
// //   return height;
// // };

// const handleDrop = (ev:any) => {
//   // Prevent default behavior (Prevent file from being opened)
//   ev.preventDefault();

//   if (ev.dataTransfer.items) {
//     // Use DataTransferItemList interface to access the file(s)
//     for (let i = 0; i < ev.dataTransfer.items.length; i++) {
//       // If dropped items aren't files, reject them
//       if (ev.dataTransfer.items[i].kind === "file") {
//         const file = ev.dataTransfer.items[i].getAsFile();
//         loadFile(file, store);
//       }
//     }
//   } else {
//     // Use DataTransfer interface to access the file(s)
//     for (let i = 0; i < ev.dataTransfer.files.length; i++) {
//       loadFile(ev.dataTransfer.files[i], store);
//     }
//   }
// };

// eslint-disable-next-line react-hooks/rules-of-hooks



const sections = [CustomUserTemplate,CustomTemplateSection,TextSection,PhotosSection,ElementsSection,UploadSection,BackgroundSection,SizeSection];

export const DesginTool = () => {
  //const height = useHeight();
  // Initializes the Facebook SDK
//  const isFbSDKInitialized = useInitFbSDK();


  // App state
  // const [fbUserAccessToken, setFbUserAccessToken] = React.useState();
  // const [fbPageAccessToken, setFbPageAccessToken] = React.useState();
  // const [postText, setPostText] = React.useState<string>();
  // const [postImage, setPostImage] = React.useState<string>();
  // const [isPublishing, setIsPublishing] = React.useState(false);

  // Logs in a Facebook user
  // const logInToFB = React.useCallback(() => {
  //   window.FB.login((response:any) => {
  //     setFbUserAccessToken(response.authResponse.accessToken);
  //   });
  // }, []);

  // // Logs out the current Facebook user
  // const logOutOfFB = React.useCallback(() => {
  //   window.FB.logout(() => {
  //     setFbUserAccessToken(null!);
  //     setFbPageAccessToken(null!);
  //   });
  // }, []);

  // Checks if the user is logged in to Facebook
  // React.useEffect(() => {
  //   if (isFbSDKInitialized) {
  //     window.FB.getLoginStatus((response:any) => {
  //       setFbUserAccessToken(response.authResponse?.accessToken);
  //     });
  //   }
  // }, [isFbSDKInitialized]);

  // Fetches an access token for the page
  // React.useEffect(() => {
  //   if (fbUserAccessToken) {
  //     window.FB.api(
  //       `/${PAGE_ID}?fields=access_token&access_token=${fbUserAccessToken}`,
  //       ({ access_token }:any) => setFbPageAccessToken(access_token)
  //     );
  //   }
  // }, [fbUserAccessToken]);

  // Publishes a post on the Facebook page
 // console.log(postImage);
  // const sendPostToPage = React.useCallback(() => {
  //   setIsPublishing(true);
  //  console.log( imageUrlFacebook);
  //   window.FB.api(
  //     `/${PAGE_ID}/feed`,
  //     "POST",
  //     {
  //      link:imageUrlFacebook,
  //      message: postText,
  //     access_token: fbPageAccessToken,
  //     },
  //     () => {
  //       setPostText('');
  //       setPostImage('');
  //       setIsPublishing(false);
  //     }
  //   );
  // }, [fbPageAccessToken, postImage, postText]);
  // // To prepare image
  // const image = store.toDataURL();
  // console.log(image.preview);
  //const previewBlob =  blobFile(image);
  // const [imageUrlFacebook,setimageUrlFacebook]=useState('');
  // const url:any = useSelector((state:any)=>state.auth.picture);
  // useEffect(()=>{

  //   setimageUrlFacebook(url);
  // },[url])

  return (
    <>
      {/* <div
      // style={{
      //   width: "100vw",
      //   height: height + "px",
      //   display: "flex",
      //   flexDirection: "column",
      // }}
      onDrop={handleDrop}
    ></div> */}

    <TopBar store={store}/>




    <PolotnoContainer style={{width: '100%', height: '100vh'}}>
    <FacebookComponents/>
      <SidePanelWrap>
        <SidePanel store={store} sections={sections} defaultSection="custom-template-apis" />
      </SidePanelWrap>
      <WorkspaceWrap>
        <Toolbar store={store}  />
        <Workspace store={store} />
        <ZoomButtons store={store} />
      </WorkspaceWrap>
    </PolotnoContainer>
    </>
  )
}
