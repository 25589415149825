import {useLocation} from 'react-router-dom'
import axios from 'axios'
import {useDispatch} from 'react-redux'
import {useEffect} from 'react'
import * as auth from '../redux/AuthRedux'

declare var process: {
  env: {
    REACT_APP_API_URL: string
  }
}

export function ConfirmEmail() {
  const query = new URLSearchParams(useLocation().search)
  const token = query.get('token')
  const API_URL = process.env.REACT_APP_API_URL
  const dispatch = useDispatch()

  //console.log(query);

  useEffect(() => {
    // console.log(api)
    const api = () => {
      return axios.get(`${API_URL}/api/auth/email/verify/${token}`)
    }
    api().then((data: any) => {
      if (data.data.data === undefined) {
        dispatch(auth.actions.login(''))
      } else {
        dispatch(auth.actions.login(data.data.data.access_token))
      }
    })
  }, [API_URL, dispatch, token])

  return (
    <>
      <div className='fv-help-block'>
        <span role='alert'></span>
      </div>
    </>
  )
}
