import axios from 'axios'
// import {AuthModel} from '../models/AuthModel'
import {CustomModel} from '../models/CustomModel'

declare var process : {
  env: {

    REACT_APP_API_URL:string
  }
}

 const API_URL = process.env.REACT_APP_API_URL;


export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/get-user`
export const LOGIN_URL = `${API_URL}/api/auth/login`
export const REGISTER_URL = `${API_URL}/api/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/api/auth/forgot-password`

// Server should return AuthModel
export function login(email: string, password: string) {
  //LOGIN_URL
  return axios.post(`${API_URL}/api/auth/login`, {email, password})
}

export function socialLogin (access_token: string ,provider_name: string){

  return axios.post(`${API_URL}/api/auth/social/login`, {access_token,provider_name})

}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post(REGISTER_URL, {
    email,
    name:firstname +" " + lastname,
    password,
  })
}
// forget password


// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<CustomModel>(GET_USER_BY_ACCESSTOKEN_URL)

}
