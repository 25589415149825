// import React, { useDispatch }  from "react";

import { observer } from "mobx-react-lite";
import { InputGroup } from "@blueprintjs/core";
import { useInfiniteAPI } from "polotno/utils/use-api";
import { SectionTab } from "polotno/side-panel";
import * as auth from '../../modules/auth/redux/AuthRedux';
// import {picture} from '../../modules/auth/redux/AuthCRUD'
import { ImagesGrid } from "polotno/side-panel/images-grid";
//import { ImagePreview } from "./ImagePreview";
import { useDispatch } from 'react-redux';

const API_URL = process.env.REACT_APP_API_URL;
// const ImagePreview = require("./ImagePreview");
const id:any = ()=>{
  const data:any = localStorage.getItem('persist:v100-demo2-auth');

  if(data != null){

  return JSON.parse(JSON.parse(data).user).data.id;
  }
  return;
}


export const UserTemplate = observer(({ store }: any) => {
  // const [ImageURL,setImagePreview] = useState(null);

const dispatch = useDispatch();
  const { data, isLoading, loadMore, setQuery} = useInfiniteAPI({
    getAPI: ({page}) =>`${API_URL}/api/my-templates?page=${page}&user_id=${id()}`
  ,getSize: (res) => Math.floor(res.count / res.limit),
  });
  return (


    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>

      <InputGroup
        leftIcon="search"
        placeholder="Search..."
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        style={{
          marginBottom: "20px",
        }}
      />
      <ImagesGrid
        shadowEnabled={false}
        images={data?.map((data) => data.data).flat()!}
        getPreview={(item) =>item.preview}
        isLoading={isLoading}
        onSelect={async (item, pos, element) => {
          // download selected json
        //  console.log( await store.toDataURL());
        //  setImagePreview(item.preview);
        dispatch(auth.actions.picture(item.preview));
          const req = await fetch(item.json_file);
         // console.log(item.preview);
          const json = await req.json();
          // just inject it into store
          store.loadJSON(json);
        }}
        rowsNumber={2}
      loadMore={loadMore}
      />

    </div>

  );
});

// define the new custom section
export const CustomUserTemplate = {
  name: "custom-User-apis",
  Tab: (props:any) => (
    <SectionTab name="Your Gallery" {...props}>
<i className="blk fas fa-cloud-download-alt"></i>
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: UserTemplate,
};
